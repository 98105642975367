<template>
  <div class="container">


    <div class="conference-author mt-4 mb-4">
<!--      <h4 class="text-center mb-4">Международная научная конференция «Сагадиевские чтения – 2022»</h4>-->
      <h4 class="text-center mb-3">Международная научная конференция
        <br><strong>«Сагадиевские Чтения 2024 – Казахстан в период глобальных трансформаций: вызовы и возможности»</strong></h4>

<!--      <div class="row mt-4 mb-4">-->
<!--        <p>-->
<!--          <b>По многочисленным просьбам потенциальных авторов срок отправки статей продлен до 27 марта 2022г.</b>-->
<!--        </p>-->
<!--      </div>-->


      <div class="row mt-3 mb-4 text-center">
<!--        <router-link to="/articles-formation-requirements" target="_blank">-->
        <router-link to="/conference/forum-requirements" target="_blank">
          ИНФОРМАЦИОННОЕ ПИСЬМО
        </router-link>

          <br>
<!--          <a href="https://back.uib.kz/common/proceeding.pdf">СБОРНИК</a>-->

      </div>

      <div class="row mt-4 mb-4" v-if="conferenceAuthor_form.conferenceUser.length == 0">
        <div class="col-md-12">
          <Button type="button" icon="pi pi-plus" label="Регистрация на конференцию" @click="openRegisterToConferenceDialog"/>
        </div>
      </div>

      <div v-if="conferenceAuthor_form.conferenceUser.length != 0">


        <div class="card">
          <div class="card-body">
            <div class="card-title mb-4">
              <div class="row">
                <div class="col-12 ml-3">
                  <h2 class="d-block"
                      style="font-size: 1.3rem; font-weight: bold;">Информация об авторе</h2>
                </div>
              </div>
            </div>

            <div>
              <p><strong>ФИО:</strong>
                {{ conferenceAuthor_form.conferenceUser[0].last_name }} {{ conferenceAuthor_form.conferenceUser[0].first_name }} {{ conferenceAuthor_form.conferenceUser[0].middle_name }}
              </p>
              <p><strong>ВУЗ:</strong>
                {{ conferenceAuthor_form.conferenceUser[0].university.name }}
              </p>

              <p><strong>Телефон:</strong>
                {{ conferenceAuthor_form.conferenceUser[0].phone }}
              </p>

              <p><strong>Email:</strong>
                {{ conferenceAuthor_form.conferenceUser[0].email }}
              </p>

              <p><strong>Email корреспондента:</strong>
                {{ conferenceAuthor_form.conferenceUser[0].email_corespondent }}
              </p>

            </div>
          </div>
        </div>


        <div class="row mt-4 mb-4">
          <div class="col-md-12" v-if="checkEndDate">
            <Button type="button" icon="pi pi-plus" label="Добавить статью" @click="openAddArticleDialog"/>
          </div>
          <div v-else>Срок подачи статьи истек</div>
        </div>

        <div class="table-responsive" v-if="conferenceAuthor_form.conferenceArticle.length != 0">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>№</th>
              <th>Название статьи</th>
              <th>Автор</th>
              <th>Секция</th>
              <th>Язык</th>
              <th>Статьи</th>

            </tr>
            </thead>
            <tbody>
            <tr v-for="(article, articleIndex) in conferenceAuthor_form.conferenceArticle" :key="articleIndex">
              <td>{{articleIndex+1}}</td>
              <td>{{article.theme}}</td>
              <td>{{article.last_name}} {{article.first_name}} {{article.middle_name}}</td>
              <td>{{article.conference_section_name}}</td>
              <td>{{article.language}}</td>

              <td>
                <table class="table table-sm">
                  <tbody>
                  <tr>
                    <th>Дата получения</th>
                    <th>Файлы</th>
                    <th>% плагиата (локальный)</th>
                    <th>% плагиата (strike)</th>
                    <th>Статус статьи</th>
                    <th>Действия</th>
                  </tr>
                  <tr v-for="(userArticle, userArticleIndex) in article.user_articles" :key="userArticleIndex">
                    <td>{{convertGraduateWorkTimestampToDate(userArticle.created_at)}}</td>
                    <td>
                      <div class="my-1">
                        <a :href="`${userArticle.article_file}`">
                          Версия {{userArticleIndex+1}}
                        </a>
                      </div>
                      <div class="my-1">
                        <a :href="`${userArticle.literature_file}`">
                          Список литературы {{userArticleIndex+1}}
                        </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="userArticle.local_plagiat">
                        {{userArticle.local_plagiat}}
                      </div>
                      <div v-else>
                        Результата нет
                      </div>

                    </td>
                    <td>
                      <div v-if="userArticle.strike_plagiat">
                        {{userArticle.strike_plagiat}}
                      </div>
                      <div v-else>
                        Результата нет
                      </div>

                    </td>
                    <td>
                      <div v-if="userArticle.status.length">
<!--                        <a href="#" @click="openCommentDialog(userArticle.conference_article_id)">{{userArticle?.status[0]?.status_text}}</a>-->
                        <Button label="Статусы" class="p-button-secondary p-button-text m-1"
                                @click="openCommentDialog(article.author_id, userArticle.conference_article_id)"/>
                      </div>
                      <div v-else>
                        Проверяется на антиплагиат
                      </div>

                    </td>
                    <td>
                    <!--   v-if="userArticle.status[0].status_id == 3"                   -->
                      <div v-if="userArticle?.status[userArticle.status.length - 1]?.conference_article_status_id == 3 || userArticle?.status[userArticle.status.length - 1]?.conference_article_status_id == 6">
                        <Button icon="pi pi-pencil" class="p-button-warning" @click="openUpdateArticleFileDialog(userArticle.conference_article_id)"/>
                      </div>
                      <div v-else-if="userArticle?.status[userArticle.status.length - 1]?.conference_article_status_id == 7">
                        <div v-for="(review, reviewIndex) in userArticle.reviewers" :key="reviewIndex">
                          <a href="#" @click="openInfoReviewDialog(review?.conference_review_id, article.author_id, userArticle.conference_article_id)">{{review?.lastname}} {{review?.firstname}}</a>

                        </div>

                      </div>
                      <div v-else>
                        Статус не соответствует
                      </div>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>

            </tr>
            </tbody>
          </table>
        </div>









      </div>





      <Dialog v-model:visible="addArticleDisplay" :style="{width: '50vw'}" :closable="false">
        <template #header>
          <h5>Добавить статью</h5>
        </template>

        <div class="col-md-12 mt-4">
          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Конференция</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeConferenceArticle('conference_id', $event)" disabled>
<!--                [{id: 0, name: 'Выберите конференцию'}, ...conferenceAuthor_form.conference]-->
                <option v-for="(item, index) in conferenceAuthor_form.conference"
                        :value="item.id"
                        :selected="item.id === conferenceAuthor_form.conferenceUser[0].conference_id"
                        :key="index" >{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Секция</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeConferenceArticle('conference_section_id', $event)">
                <option v-for="(item, index) in [{id: 0, name: 'Выберите секцию'}, ...conferenceAuthor_form.conferenceSection]"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Тема статьи</label>
            <div class="col-md-9">
              <input id="article_theme" type="text" class="form-control"
                     placeholder="Тема статьи"
                     @change="changeConferenceArticle('theme',$event)">
            </div>
          </div>


          <div class="form-group row mt-4">
            <label class="col-md-12 col-form-label text-center"><b>ФИО соавтора</b></label>


            <div class="col-md-12">
              <div class="form-row" v-for="(coAuthorInfo, index) in coAuthorInfos" :key="index">


                <div class="job-remove-item__button d-flex flex-row-reverse">
                  <i class="fa fa-times" @click="deleteRow(index)"></i>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">ВУЗ соавтора</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" v-if="conferenceAuthor_form.conferenceUser[0].university_id == 1"
                           placeholder="ВУЗ соавтора" value="UIB"
                           disabled>



                    <select class="form-control form-select" v-if="conferenceAuthor_form.conferenceUser[0].university_id != 1"
                            @input="changeCoAuthor(index, 'university_id', $event)">
                      <option v-for="(item, index) in [{id: 0, name: 'Выберите университет'}, ...conferenceAuthor_form.university]"
                              :value="item.id"
                              :key="index">{{ item.name }}
                      </option>
                    </select>


                  </div>
                </div>

                <div class="form-group row mt-4" v-if="conferenceAuthor_form.conferenceUser[0].university_id == 1">
                  <label class="col-md-3 col-form-label">Поиск по фамилии</label>
                  <div class="col-md-9">


                    <DropdownListConferenceCoAuthor
                        :options="options"
                        :value="item"
                        @change="changeSelectConferenceAuthor(index, $event)"
                        @search="onSearch"/>


                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Фамилия</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="Фамилия" v-if="conferenceAuthor_form.conferenceUser[0].university_id == 1"
                           :value="coAuthorInfos[index]['last_name']"
                           @change="changeCoAuthor(index,'last_name', $event)">

                    <input type="text" class="form-control" placeholder="Фамилия" v-if="conferenceAuthor_form.conferenceUser[0].university_id != 1"
                           @change="changeCoAuthor(index,'last_name',$event)">
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Имя</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="Имя"
                           :value="coAuthorInfos[index]['first_name']"
                           @change="changeCoAuthor(index,'first_name', $event)">
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Отчество</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="Отчество"
                           :value="coAuthorInfos[index]['middle_name']"
                           @change="changeCoAuthor(index,'middle_name', $event)">
                  </div>
                </div>


                <hr>


              </div>
              <div class="form-group">
                <button @click="addRow" type="button" class="btn btn-secondary"><i
                    class="fas fa-plus-circle"></i>
                  Добавить
                </button>
              </div>


            </div>


          </div>


          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">ВУЗ первого автора</label>
            <div class="col-md-9">
<!--              <input type="text" class="form-control"-->
<!--                     placeholder="ВУЗ первого автора"-->
<!--                     value="UIB" disabled>-->

              <select class="form-control form-select" disabled>
                <option v-for="(item, index) in [{id: 0, name: 'Выберите ВУЗ'}, ...conferenceAuthor_form.university]"
                        :value="item.id"
                        :selected="item.id == conferenceAuthor_form.conferenceUser[0].university_id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>


          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Язык подачи статьи</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeConferenceArticle('language_id', $event)">
                <option v-for="(item, index) in [{id: 0, name: 'Выберите язык'}, ...languages]"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Файл статьи</label>
            <div class="col-md-9">
              <input type="file" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     title="Файл с расширением .doc и .docx"
                     class="form-control" @change="handleFileUpload($event)">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Файл списка литературы</label>
            <div class="col-md-9">
              <input type="file" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     title="Файл с расширением .doc и .docx"
                     class="form-control" @change="handleFileUploadLiterature($event)">
            </div>
          </div>



        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeAddArticleDialog"/>
          <Button label="Добавить" icon="pi pi-check" @click="postConferenceArticle"/>
        </template>
      </Dialog>








      <Dialog v-model:visible="registerToConferenceDisplay" :style="{width: '50vw'}" :closable="false">
        <template #header>
          <h5>Регистрация на конференцию</h5>
        </template>

        <div class="col-md-12 mt-4">

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">ВУЗ</label>
            <div class="col-md-9">

              <select class="form-control form-select"
                      @input="changeConferenceUser('university_id', $event)">
                <option v-for="(item, index) in [{id: 0, name: 'Выберите ВУЗ'}, ...conferenceAuthor_form.university]"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Конференция</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeConferenceUser('conference_id', $event)">
                <option v-for="(item, index) in [{id: 0, name: 'Выберите конференцию'}, ...conferenceAuthor_form.conference]"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>


          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Фамилия</label>
            <div class="col-md-9">
              <input type="text" class="form-control" placeholder="Фамилия"
                     :value="conferenceAuthor_form.newConferenceUser.last_name"
                     @change="changeConferenceUser('last_name', $event)">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Имя</label>
            <div class="col-md-9">
              <input type="text" class="form-control" placeholder="Имя"
                     :value="conferenceAuthor_form.newConferenceUser.first_name"
                     @change="changeConferenceUser('first_name', $event)">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Отчество</label>
            <div class="col-md-9">
              <input type="text" class="form-control" placeholder="Отчество"
                     :value="conferenceAuthor_form.newConferenceUser.middle_name"
                     @change="changeConferenceUser('middle_name', $event)">
            </div>
          </div>






          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Телефон</label>
            <div class="col-md-9">
              <input type="tel" class="form-control" placeholder="Телефон"
                     :value="conferenceAuthor_form.newConferenceUser.phone"
                     @change="changeConferenceUser('phone', $event)">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Email</label>
            <div class="col-md-9">
              <input type="email" class="form-control" placeholder="Email"
                     :value="conferenceAuthor_form.newConferenceUser.email"
                     @change="changeConferenceUser('email', $event)">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Email корреспондента</label>
            <div class="col-md-9">
              <input type="email" class="form-control" placeholder="Email"
                     @change="changeConferenceUser('email_corespondent', $event)">
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeRegisterToConferenceDialog"/>
          <Button label="Зарегистрироваться" icon="pi pi-check" @click="registerToConference"/>
        </template>
      </Dialog>




      <!-- statuses modal -->
      <Dialog header="Статусы" v-model:visible="commentDisplay"
              :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div>
          <div v-for="(status, statusIndex) in statusesByArticle" :key="statusIndex">
            <div class="my-1">
              <div class="fw-bold">
                {{statusIndex+1}}. {{status.name}}
              </div>
              <div class="ms-2">
                Отправитель: {{status.lastname}} {{status.firstname}}
              </div>
              <div v-if="status.text" class="ms-2">
                Комментарий: {{status.text}}
              </div>
              <hr>
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeCommentDialog"/>
        </template>
      </Dialog>
      <!-- end statuses modal -->


      <!-- info review dialog -->
      <Dialog v-model:visible="infoReviewDisplay" :style="{width: '50vw'}" :closable="false" :modal="true">
        <template #header>
          <h5>Рецензия</h5>
        </template>

        <div class="col-md-12 mt-4">
          <ol>
            <li>
              Введение: {{ reviewInfo.score_1 || 'Пусто'}}
              <br>
              Комментарий: {{ reviewInfo?.comment_1 || 'Пусто' }}
            </li>

            <li>
              Задача исследования: {{ reviewInfo.score_2 || 'Пусто'}}
              <br>
              Комментарий: {{ reviewInfo.comment_2 || 'Пусто'}}
            </li>

            <li>
              Обзор литературы: {{ reviewInfo.score_3 || 'Пусто'}}
              <br>
              Комментарий: {{ reviewInfo.comment_3 || 'Пусто'}}
            </li>

            <li>
              План и методы исследования: {{ reviewInfo.score_4 || 'Пусто'}}
              <br>
              Комментарий: {{ reviewInfo.comment_4 || 'Пусто'}}
            </li>

            <li>
              Полученные данные и обсуждение: {{ reviewInfo.score_5 || 'Пусто'}}
              <br>
              Комментарий: {{ reviewInfo.comment_5 || 'Пусто'}}
            </li>

            <li>
              Научный вклад статьи: {{ reviewInfo.score_6 || 'Пусто'}}
              <br>
              Комментарий: {{ reviewInfo.comment_6 || 'Пусто'}}
            </li>

            <li>
              Выводы: {{ reviewInfo.score_7 || 'Пусто'}}
              <br>
              Комментарий: {{ reviewInfo.comment_7 || 'Пусто'}}
            </li>

            <li>
              Технические требования к статье: {{ reviewInfo.score_8 || 'Пусто'}}
              <br>
              Комментарий: {{ reviewInfo.comment_8 || 'Пусто'}}
            </li>
          </ol>
        </div>


        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeInfoReviewDialog"/>
        </template>
      </Dialog>
      <!-- end info review dialog -->


      <Dialog v-model:visible="updateArticleFileDisplay" :style="{width: '50vw'}" :closable="false">
        <template #header>
          <h5>Обновить файл</h5>
        </template>

        <div class="col-md-12 mt-4">

          <div class="form-group row mt-4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="article_file" v-model="updateArticleFile">
              <label class="form-check-label" for="article_file">
                Файл статьи
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="literature_file" v-model="updateLiteratureFile">
              <label class="form-check-label" for="literature_file">
                Файл списка литературы
              </label>
            </div>
          </div>

          <div class="form-group row mt-4" v-if="updateArticleFile">
            <label class="col-md-3 col-form-label">Обновить файл статьи</label>
            <div class="col-md-9">
              <input type="file" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     title="Файл с расширением .doc и .docx"
                     class="form-control" @change="updateHandleArticleFileUpload($event)">
            </div>
          </div>

          <div class="form-group row mt-4" v-if="updateLiteratureFile">
            <label class="col-md-3 col-form-label">Обновить файл списка литературы</label>
            <div class="col-md-9">
              <input type="file" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     title="Файл с расширением .doc и .docx"
                     class="form-control" @change="updateHandleLiteratureFileUpload($event)">
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeUpdateArticleFileDialog"/>
          <Button label="Обновить" icon="pi pi-check" @click="putArticleFile"/>
        </template>
      </Dialog>

    </div>
  </div>



</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import DropdownListConferenceCoAuthor from "@/components/common/DropdownListConferenceCoAuthor.vue";
import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate"
export default {
  name: "ConferenceAuthor",
  components: {
    DropdownListConferenceCoAuthor,
  },
  data() {
    return {
      options: [],
      languages: [
        { id: 39, name: 'English' },
        { id: 82, name: 'Қазақ тілі ' },
        { id: 137, name: 'Русский язык ' }
      ],
      addArticleDisplay: false,
      registerToConferenceDisplay: false,
      commentDisplay: false,
      updateArticleFileDisplay: false,
      coAuthorInfos: [],
      updateArticleFile: false,
      updateLiteratureFile: false,
      author_id: 0,
      conference_article_id: 0,
      infoReviewDisplay: false,
      conference_review_id: 0,
      endDate: 1710525599
    }
  },
  computed: {
    ...mapState('conferenceAuthor', ['conferenceAuthor_form']),
    statusesByArticle() {
      return this.conferenceAuthor_form.conferenceArticle
          .find(i => i.author_id == this.author_id)
          ?.user_articles.find(i => i.conference_article_id == this.conference_article_id)?.status || []
    },
    reviewInfo() {
      return this.conferenceAuthor_form.conferenceArticle
          .find(i => i.author_id == this.author_id)
          ?.user_articles.find(i => i.conference_article_id == this.conference_article_id)?.reviewers.find(i => i.conference_review_id == this.conference_review_id) || []
    },
    checkEndDate(){
      const date = Math.round(new Date().getTime()/1000)
      return date < this.endDate
    }
  },

  methods: {
    ...mapActions('conferenceAuthor', ['GET_PPS_INFO', 'GET_CONFERENCE', 'GET_SP_UNIVERSITY',
      'GET_CONFERENCE_USER', 'POST_CONFERENCE_USER', 'GET_CONFERENCE_SECTION', 'POST_CONFERENCE_ARTICLE',
      'GET_CONFERENCE_ARTICLE', 'GET_AUTHORS_BY_LAST_NAME', 'GET_CONFERENCE_COMMENT', 'PUT_CONFERENCE_ARTICLE']),

    ...mapMutations('conferenceAuthor', ['SET_NEW_CONFERENCE_USER_DATA', 'SET_NEW_CONFERENCE_ARTICLE_DATA',
    'SET_NEW_CONFERENCE_ARTICLE_FILE', 'SET_NEW_CONFERENCE_ARTICLE_LITERATURE_FILE',
      'CLEAR_CONFERENCE_ARTICLE', 'SET_UPDATE_ARTICLE_FILE', 'SET_UPDATE_LITERATURE_FILE','SET_UPDATE_ARTICLE_ID']),
    convertGraduateWorkTimestampToDate,
    openCommentDialog(author_id, conference_article_id) {
      this.author_id = author_id
      this.conference_article_id = conference_article_id
      console.log(conference_article_id, 'conference_article_id')
      this.commentDisplay = true

      this.GET_CONFERENCE_COMMENT(conference_article_id)
    },
    closeCommentDialog() {
      this.commentDisplay = false
    },
    openUpdateArticleFileDialog(conference_article_id) {
      console.log(conference_article_id, 'conference_article_id')
      this.updateArticleFileDisplay = true
      this.SET_UPDATE_ARTICLE_ID(conference_article_id)
    },
    closeUpdateArticleFileDialog() {
      this.updateArticleFileDisplay = false
    },
    changeConferenceUser(property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_CONFERENCE_USER_DATA({property, value})
    },
    changeConferenceArticle(property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_CONFERENCE_ARTICLE_DATA({property, value})
    },
    changeCoAuthor(index, property, e, val = 'value') {
      const value = e.target[val]
      this.coAuthorInfos[index][property] = value
      console.log(this.coAuthorInfos, 'coAuthorInfos')
    },
    async changeSelectConferenceAuthor(index, item) {
      const conferenceAuthor = item
      this.coAuthorInfos[index]['last_name'] = conferenceAuthor.lastname
      this.coAuthorInfos[index]['first_name'] = conferenceAuthor.firstname
      this.coAuthorInfos[index]['middle_name'] = conferenceAuthor.middlename
      this.coAuthorInfos[index]['user_id'] = conferenceAuthor.user_id
      console.log(this.coAuthorInfos, 'coAuthorInfos')
    },
    onSearch(search) {
      if (search.length > 3) {
        this.GET_AUTHORS_BY_LAST_NAME(search).then(json => (this.options = json));
      }
    },
    async registerToConference() {
      this.registerToConferenceDisplay = false
      await this.POST_CONFERENCE_USER()
      await this.GET_CONFERENCE_USER()
    },
    async postConferenceArticle() {
      this.addArticleDisplay = false
      const res = await this.POST_CONFERENCE_ARTICLE(this.coAuthorInfos)
      if(res){
        this.$message({title: 'Сохранено'})
      }
      await this.GET_CONFERENCE_ARTICLE()
      await this.CLEAR_CONFERENCE_ARTICLE()
    },

    openRegisterToConferenceDialog() {
      this.registerToConferenceDisplay = true
    },
    closeRegisterToConferenceDialog() {
      this.registerToConferenceDisplay = false
    },
    openAddArticleDialog() {
      this.addArticleDisplay = true
    },
    closeAddArticleDialog() {
      this.addArticleDisplay = false
      this.coAuthorInfos = []
      this.CLEAR_CONFERENCE_ARTICLE()
    },
    openInfoReviewDialog(conference_review_id, author_id, conference_article_id) {
      this.author_id = author_id
      this.conference_review_id = conference_review_id
      this.conference_article_id = conference_article_id
      this.infoReviewDisplay = true
    },
    closeInfoReviewDialog() {
      this.infoReviewDisplay = false
    },
    // openAddCoAuthorDialog() {
    //   this.addCoAuthorDisplay = true
    // },
    // closeAddCoAuthorDialog() {
    //   this.addCoAuthorDisplay = false
    // },
    addRow() {
      let university_id = 0
      if (this.conferenceAuthor_form.conferenceUser[0].university_id == 1){
        university_id = 1
      }
      else {
        university_id = ''
      }
      this.coAuthorInfos.push({
        last_name: '',
        first_name: '',
        middle_name: '',
        university_id

      });
    },

    deleteRow(index) {
      this.coAuthorInfos.splice(index, 1);
    },
    handleFileUpload(e) {
      let file_article = e.target.files[0];
      this.SET_NEW_CONFERENCE_ARTICLE_FILE(file_article)
    },
    handleFileUploadLiterature(e) {
      let literature_file = e.target.files[0];
      this.SET_NEW_CONFERENCE_ARTICLE_LITERATURE_FILE(literature_file)
    },
    updateHandleArticleFileUpload(e) {
      let update_file_article = e.target.files[0];
      this.SET_UPDATE_ARTICLE_FILE(update_file_article)
    },
    updateHandleLiteratureFileUpload(e) {
      let update_literature_file = e.target.files[0];
      this.SET_UPDATE_LITERATURE_FILE(update_literature_file)
    },
    async putArticleFile() {
      this.updateArticleFileDisplay = false
      await this.PUT_CONFERENCE_ARTICLE()
      await this.GET_CONFERENCE_ARTICLE()
    }
  },

  async mounted() {
    await this.GET_PPS_INFO()
    await this.GET_CONFERENCE()
    await this.GET_CONFERENCE_USER()
    await this.GET_SP_UNIVERSITY()
    await this.GET_CONFERENCE_SECTION(this.conferenceAuthor_form.conferenceUser[0].conference_id)
    await this.GET_CONFERENCE_ARTICLE()

  }
}
</script>

<style scoped>

</style>
